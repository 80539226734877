import { SelectAll } from './src/select_all';

// TODO: things seem to work/look fine without this
// require('chart.js/dist/Chart.min.css');

import { WeightChart } from './src/weight_chart';

$(document).ready(function() {
  SelectAll.setup();
  WeightChart.setup();
});
